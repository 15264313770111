import { useContext } from 'react'
import { ContextProps, DevelopmentContext } from './DevelopmentContext'

export const useDevelopment = (): ContextProps => {
  const { setShowHelpers, showHelpers, freeControl } = useContext(
    DevelopmentContext
  )

  return { setShowHelpers, showHelpers, freeControl }
}

export default useDevelopment
