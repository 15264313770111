import React, { Suspense } from 'react'

interface ILazy {
  children?: React.ReactNode
}

const Lazy: React.FC<ILazy> = ({ children }) => {
  const isSSR = typeof window === 'undefined'
  if (!isSSR) return <Suspense fallback={null}>{children}</Suspense>
  return null
}

export default Lazy
