import React, { useState } from 'react'

export interface ContextProps {
  readonly showHelpers: boolean | null
  readonly freeControl: boolean | null
  readonly setShowHelpers: () => void
}

export const DevelopmentContext = React.createContext<ContextProps>({
  showHelpers: true,
  freeControl: false,
  setShowHelpers: () => null,
})

export const DevelopmentProvider: React.FC<{
  initialShowHelpers: boolean
  freeControl?: boolean
}> = ({ children, initialShowHelpers, freeControl }) => {
  const [helpers, setHelpers] = useState(initialShowHelpers)

  const setShowHelpers = () => setHelpers(prev => !prev)

  return (
    <DevelopmentContext.Provider
      value={{
        setShowHelpers,
        showHelpers: helpers,
        freeControl: freeControl ?? false,
      }}
    >
      {children}
    </DevelopmentContext.Provider>
  )
}
