import React, { useState } from 'react'

import { Settings, Cursor } from '../fragments'
import {
  LocaleProvider,
  SettingsProvider,
  getInitialLocale,
  RouterProvider,
} from '../lib/Context'
import LoadingControl from './LoadingControl'

import Scene from '../scene'

import './layout.scss'
import '../variables.scss'
import '../styles/animation.scss'

const Layout = ({ children }: { children: any }) => {
  const [lng, setLng] = useState('en')

  React.useEffect(() => {
    setLng(getInitialLocale())
  }, [])

  return (
    <LocaleProvider lng={lng}>
      <RouterProvider page={0}>
        <SettingsProvider sound={false}>
          <Scene />

          <LoadingControl>
            <>
              <Cursor />

              {children}

              <Settings />
            </>
          </LoadingControl>
        </SettingsProvider>
      </RouterProvider>
    </LocaleProvider>
  )
}

export default Layout
