import React from 'react'
import { Link } from 'gatsby'

import { ToggleGroup, Flex, Card } from '../../components'
import { FadeAnimation, Typography } from '../../fragments'
import Item from './MenuItem'
import Side from '../../static/icons/side.svg'

import { useTranslation, useRouter } from '../../hooks'
import { locales } from '../../lib/Context/LocaleContext'
import { menu } from '../../text'

import Logo from '../../static/images/logo.svg'

import './style.scss'

const list = locales.map((locale: string) => ({
  label: locale.toLocaleUpperCase(),
  value: locale,
}))

const Menu = () => {
  const { getPage, setPage, count } = useRouter()
  const { tr, setLng, getLng } = useTranslation(menu)

  const onChangeLng = (value: string) => setLng(value)

  // const year = `© Copyright 2021 penhersky.net`

  return (
    <Flex className="menu-container animation-fade-opacity">
      <div className="logo-container">
        <FadeAnimation from="top">
          <Logo className="app-logo" />
        </FadeAnimation>
      </div>

      <Flex.Row align="flex-start" justify="center">
        <FadeAnimation from="right" delay={`0.${list.length}s`}>
          <div className="slider" style={{ marginTop: `${47 * getPage()}px` }}>
            {(getPage() === 0 ?? getPage() > count) || (
              <Card.Environment>
                <Side className="bar" />
              </Card.Environment>
            )}
          </div>
        </FadeAnimation>
        <Flex.Column className="menu">
          {tr('list').map((item: string, index: number) => (
            <FadeAnimation from="right" delay={`0.${index}s`} key={index}>
              <Item
                value={index}
                active={index === getPage()}
                onClick={setPage}
              >
                {item}
              </Item>
            </FadeAnimation>
          ))}
          <FadeAnimation
            from="right"
            delay={`0.${list.length}s`}
            className="menu-flag-select"
          >
            <ToggleGroup list={list} value={getLng()} onClick={onChangeLng} />
          </FadeAnimation>
        </Flex.Column>
      </Flex.Row>
      <FadeAnimation from="bottom">
        <Typography className="copyright">
          <Link to="/info" className="link menu-info-link">
            {tr('info', menu)}
          </Link>
        </Typography>
      </FadeAnimation>
    </Flex>
  )
}

export default Menu
