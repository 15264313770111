import { Vector3 } from 'three'

import normal from '../../assets/nightShore/waternormals.jpg'
import moonFloorAlbedo from '../../assets/nightShore/moon.png'

export const WATER = {
  size: 1000,
  position: new Vector3(0, 0, -1000 / 2 + 20),
  normal,
  color: '#07162C',
  sunColor: '#F2F7FC',
}

export const MOON = {
  size: 250,
  position: new Vector3(0, 200, -900),
  albedo: moonFloorAlbedo,
  color: 'white',
  distance: 1000,
  intensity: 1,
}

export const CAMERA = {
  defaultPosition: new Vector3(0, 100, -40),
  firstPosition: new Vector3(0, 10, 30),
  speed: 0.03,
  DefaultLockAt: new Vector3(0, -100, -900),
}
