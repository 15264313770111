import React from 'react'
import { useProgress } from '@react-three/drei'

import Loading from './Loading'

import './style.scss'

const LoadingControl: React.FC = ({ children }) => {
  const { progress, loaded, active, total, item } = useProgress()

  if (progress === 100) return <>{children}</>

  return <Loading />
}

export default LoadingControl
