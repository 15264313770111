import React from 'react'
import { useProgress } from '@react-three/drei'

import { Typography } from '../../fragments'

import './style.scss'

const Loading = () => {
  const { progress } = useProgress()

  return (
    <div className="loading">
      <div className="loading__content">
        <div />
        <div className="circle"></div>
        <div className="circle2"></div>

        <svg>
          <filter id="wave">
            <feTurbulence
              x="0"
              y="0"
              baseFrequency="0.009"
              numOctaves="5"
              seed="2"
            >
              <animate
                attributeName="baseFrequency"
                dur="30s"
                values="0.02;0.005;0.02"
                repeatCount="indefinite"
              />
            </feTurbulence>
            <feDisplacementMap in="SourceGraphic" scale="30" />
          </filter>
        </svg>

        <div className="loading__progress-container">
          <Typography className="loading__text">
            {progress.toFixed(0)}%
          </Typography>
          <div
            className="loading__progress"
            style={{ width: (progress / 100) * 300 }}
          />
        </div>
      </div>
    </div>
  )
}

export default Loading
