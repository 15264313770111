import React from 'react'
import classNames from 'classnames'
import { Canvas } from '@react-three/fiber'
import * as THREE from 'three'

import Development, { DevelopmentProvider } from './Development'
import Lazy from '../modules/Lazy'

const NightShore = React.lazy(() => import('./__main__/NightShore'))

import './style.scss'
import { CAMERA } from './__main__/NightShore/config'

// function Model(props: GroupProps) {
//   const group = useRef<any>()
//   const { nodes, materials }: any = useGLTF(model)

//   useFrame(() => {
//     try {
//       group.current.rotation.y = group.current.rotation.y += 0.01
//     } catch (error) {}
//   })

//   return (
//     <group ref={group} {...props} dispose={null}>
//       <mesh material={nodes.Earth.material} geometry={nodes.Earth.geometry} />
//     </group>
//   )
// }

const Scene = () => {
  return (
    <Canvas
      id="scene"
      style={{ position: 'absolute' }}
      className={classNames('cursor-hidden')}
      camera={{
        position: CAMERA.defaultPosition,
      }}
      gl={{ antialias: true, toneMapping: THREE.NoToneMapping }}
      linear
    >
      <DevelopmentProvider
        initialShowHelpers={process.env.NODE_ENV === 'development'}
        freeControl={process.env.NODE_ENV === 'development'}
      >
        <Lazy>
          <NightShore />
        </Lazy>
      </DevelopmentProvider>

      {process.env.NODE_ENV === 'development' && <Development />}
    </Canvas>
  )
}

export default Scene
