export const home = {
  title:
    'Mykhailo Penhersky | Home Page | Full-stack Software Engineer | Михайло Пенхерський',
  description:
    'Mykhailo Penhersky Full-stack Software Engineer working with Node.js, React.js, Typescript, AWS',
  meta: [
    {
      name: 'keywords',
      content:
        'Mykhailo Penhersky, Full-stack Software Engineer, Михайло Пенхерський, Node.js Developer, React.js Developer, Mykhailo Penkherskyi',
    },
  ],
}

export const siteInfo = {
  title:
    'Site system and general info | Mykhailo Penhersky | Home Page | Full-stack Software Engineer | Михайло Пенхерський',
  description:
    'Mykhailo Penhersky Full-stack Software Engineer working with Node.js, React.js, Typescript, AWS',
  meta: [
    {
      name: 'keywords',
      content:
        'Site info, Mykhailo Penhersky, Full-stack Software Engineer, Михайло Пенхерський, Node.js Developer, React.js Developer, Mykhailo Penkherskyi',
    },
  ],
}
